import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { mapValueToText } from "@/helpers/audit";

const PX_PER_INCH = 150;
const pxToInch = (px) => Number.parseInt(px, 10) / PX_PER_INCH;
const pxToPt = (px) => Number.parseInt(px, 10) * (72 / PX_PER_INCH);

export default class PdfPageBuilder {
  constructor() {
    this.doc = new jsPDF({
      putOnlyUsedFonts: true,
      unit: "in",
    });

    this.PAGE_WIDTH = this.doc.internal.pageSize.width;
    this.PAGE_HEIGHT = this.doc.internal.pageSize.height;
    this.SCALE_FACTOR = this.doc.internal.scaleFactor;

    this.PADDING = pxToInch(50);
    this.fontSize = pxToPt(16);
    this.positionY = this.PADDING / 2;
    this.isRelative = false;
    this.headerHeight = pxToInch(125);

    this.reset();
  }

  loadFont(file, name, style) {
    this.doc.addFont(file, name, style);
  }

  static pxToPt = pxToPt;
  static pxToInch = pxToInch;

  reset() {
    this.doc.setFontSize(this.fontSize);
    this.doc.setLineHeightFactor(1.5);
    this.doc.setFont("Inter", "normal");
    this.doc.setTextColor(0, 0, 0, 0.87);
  }

  relative(styles, context) {
    if (styles.marginTop) {
      this.increasePosition(pxToInch(styles.marginTop));
    }

    this.isRelative = true;
    context(this);
    this.isRelative = false;

    this.increasePosition(pxToInch(styles.height));
  }

  addRect(styles = {}) {
    styles.backgroundColor && this.doc.setFillColor(styles.backgroundColor);

    const width = styles.width ? pxToInch(styles.width) : this.PAGE_WIDTH;
    const height = pxToInch(styles.height || 280);

    this.doc.rect(0, this.positionY, width, height, "F");
  }

  addCircle(radius, styles = {}) {
    const style = this.processStyles(styles);

    const x = this.PADDING + style.marginLeft;
    const y = this.positionY + style.marginTop;

    this.doc.circle(x, y, pxToInch(radius), "F");
  }

  addAbsoluteRect(styles = {}) {
    styles.backgroundColor && this.doc.setFillColor(styles.backgroundColor);

    const width = styles.width ? pxToInch(styles.width) : this.PAGE_WIDTH;
    const height = pxToInch(styles.height || 280);

    this.doc.rect(0, 0, width, height, "F");
  }

  addHorizontalLine(styles) {
    const style = this.processStyles(styles);

    const { PAGE_WIDTH, PADDING, positionY } = this;
    const y = positionY + style.marginTop;

    this.doc.setDrawColor(1, 198, 208);
    this.doc.setLineWidth(pxToInch(1));
    this.doc.line(PADDING, y, PAGE_WIDTH - PADDING, y);

    this.increasePosition(style.marginTop + pxToInch(1));
  }

  addVerticalLine(styles = { width: 25 }) {
    const style = this.processStyles(styles);

    const x = this.PADDING + style.margin.left;
    const y = this.positionY + style.margin.top;

    this.doc.setDrawColor(1, 198, 208);
    this.doc.setLineWidth(pxToInch(1));
    this.doc.line(x, y, x, y + style.width);

    this.increasePosition(style.width);
  }

  addImage(source, type, styles) {
    const style = this.processStyles(styles);
    const { width, height, margin } = style;

    const x = this.PADDING + margin.left;
    const y = this.positionY + margin.top;

    this.doc.addImage(source, type, x, y, width, height);

    this.increasePosition(height + margin.top);
  }

  addText(text, styles) {
    const style = this.processStyles(styles);

    const { PAGE_WIDTH, PADDING, positionY } = this;
    let y = positionY + style.marginTop;
    const x = PADDING + style.marginLeft;

    const fontSize = style.fontSize ? pxToPt(style.fontSize) : pxToPt(16); // TODO fix
    const lines = this.doc.splitTextToSize(text, PAGE_WIDTH - PADDING * 2);
    const height = fontSize / this.SCALE_FACTOR;
    const descent = height * (this.doc.getLineHeightFactor() - 1);

    if (styles?.backgroundColor || styles[1]?.backgroundColor) {
      const totalTextHeight =
        style.margin.top +
        style.margin.bottom +
        lines.length * (height + descent);
      this.doc.rect(
        styles.rectWidth ? PADDING : 0,
        positionY + style.marginTop,
        styles.rectWidth || PAGE_WIDTH,
        totalTextHeight,
        "F"
      );
    }

    this.increasePosition(style.margin.top);

    lines.forEach((line) => {
      y += height + descent;
      this.doc.text(line, x, y - descent);
      const isNewPage = this.increasePosition(height + descent);
      if (isNewPage) y = this.positionY;
    });

    this.increasePosition(style.margin.bottom);

    this.reset();
  }

  addBadge(text, styles) {
    const style = this.processStyles(styles);

    let [hPadding, vPadding] = [pxToInch(10), pxToInch(3)];

    const x = this.PADDING + style.marginLeft;
    const y = this.positionY + style.marginTop;
    const maxTextWidthInch = 1.13;
    const maxRectWidthInch = 1.25;

    const textWidth = this.doc.getTextWidth(text);
    const textHeight = this.doc.getFontSize() / this.SCALE_FACTOR ;

    let width = textWidth > maxTextWidthInch ? maxRectWidthInch : textWidth + hPadding * 2;
    let height = textHeight + vPadding * 4; // not sure why 4, but it works

    const numberOfLineBreaks = textWidth / maxTextWidthInch;
    const ceilRoundLineBreaks = Math.ceil(numberOfLineBreaks);

    if (numberOfLineBreaks > 1) {
      height *= ceilRoundLineBreaks;
      [hPadding, vPadding] = [pxToInch(10 * ceilRoundLineBreaks), pxToInch(3 * ceilRoundLineBreaks)];
    }

    this.doc.roundedRect(x, y, width, height, height / 2, height);
    this.doc.text(text, x + hPadding, y + textHeight + vPadding, { maxWidth: pxToInch(170) });

    this.reset();
  }

  addCard(value, label, styles = {}) {
    this.processStyles(styles);

    const { PADDING } = this;
    const width = pxToInch(styles?.width || 250);
    const height = pxToInch(80);
    const radius = pxToInch(6);

    let positionX = PADDING + pxToInch(styles.marginLeft || 0);
    let positionY = this.positionY + pxToInch(styles.marginTop || 0);

    this.doc.setLineHeightFactor(1);

    // Card container
    this.doc.setDrawColor(0, 0, 0, 0.23);
    this.doc.setLineWidth(pxToInch(1));
    this.doc.roundedRect(
      positionX,
      positionY,
      width,
      height,
      radius,
      radius
    );

    let xAlignment = pxToInch(16) + `${value}`.length * pxToInch(7);
    if (`${value}`.length > 10) xAlignment = 0.85; // to avoid large padding for long text

    // Card value
    positionX += xAlignment; // TODO make better alignment
    this.doc.setFontSize(pxToPt(24));
    this.doc.setFont("Inter", "semibold");
    this.doc.text(
      `${value}`,
      positionX,
      positionY + height / 2 + pxToInch(24) / 2.5,
      { align: "center" }
    );

    // Card value/label separation line
    positionX += xAlignment;
    this.doc.setDrawColor(1, 198, 208);
    this.doc.setLineWidth(pxToInch(1));
    this.doc.line(
      positionX,
      positionY + pxToInch(20),
      positionX,
      positionY + pxToInch(60)
    );

    // Card label
    positionX += pxToInch(16);
    this.doc.setFontSize(pxToPt(14));
    this.doc.setTextColor(0, 0, 0, 0.6);
    this.doc.text(
      label,
      positionX,
      positionY + height / 2 + pxToInch(14) / 2.5
    );

    this.increasePosition(height);
    this.reset();
  }

  addTable(
    { html, columns, body, beforeCellPrint, headStyles },
    styles = {}
  ) {
    const style = this.processStyles(styles);

    let tableFinalY;

    autoTable(this.doc, {
      html,
      columns,
      body,
      theme: "plain",
      startY: this.positionY + style.marginTop,
      margin: style.margin,
      styles: { fontSize: pxToPt(14) },
      headStyles,
      rowPageBreak: "avoid",
      didParseCell: (data) => {
        if (data.cell.raw?.style?.minWidth) {
          const minWidth = parseInt(data.cell.raw.style.minWidth, 10);
          data.cell.styles.minCellWidth = pxToInch(minWidth);
        }
        if (typeof beforeCellPrint === "function") {
          beforeCellPrint(data);
        }
      },
      didDrawPage: (data) => {
        tableFinalY = data.cursor.y;

        // set top margin for the second and following pages
        data.settings.margin.top = this.headerHeight;
      },
    });

    this.positionY = tableFinalY + style.marginBottom;
  }

  addAnswerForm(sections, caseEvaluation) {
    const { PADDING } = this;
    const marginTopForAnswer = 8;
    let positionX = PADDING;

    sections.forEach((value) => {
      const sectionTitle = value.title || "";
      positionX = PADDING + 20;
      this.addText(sectionTitle, {
        fontSize: 16,
        font: ["Inter", "bold"],
        marginLeft: positionX,
        backgroundColor: "#ebfafb",
        rectWidth: this.PAGE_WIDTH - PADDING * 2,
      });

      value.criteria.forEach((criteria) => {
        const mappedValue = mapValueToText(
          caseEvaluation[criteria._id].value
        );
        const mappedComment = caseEvaluation[criteria._id].comment;
        const questionTitle = criteria.text;

        positionX = PADDING + 30;
        this.addText(questionTitle, {
          fontSize: 16,
          ...(mappedValue === "No" ? { color: ["#ffa72d"] } : {}),
          marginTop: 10,
          font: ["Inter", "semibold"],
          marginLeft: positionX,
        });

        positionX += marginTopForAnswer;

        if (mappedValue !== "-") this.addText(`Answer: ${mappedValue}`, {
          fontSize: 14,
          font: ["Inter", "regular"],
          marginLeft: positionX,
        });
        if (mappedComment) this.addText(`Comment: ${mappedComment}`, {
          fontSize: 14,
          font: ["Inter", "regular"],
          marginLeft: positionX,
        });
      });
    });
  }

  processStyles(styles = {}) {
    let style = { ...styles };
    if (Array.isArray(styles)) {
      style = styles.reduce((acc, s) => ({ ...acc, ...s }), {});
    }

    style.font && this.doc.setFont(...style.font);
    style.fontSize && this.doc.setFontSize(pxToPt(style.fontSize));
    style.color && this.doc.setTextColor(...style.color);
    style.lineHeight && this.doc.setLineHeightFactor(style.lineHeight);
    style.backgroundColor && this.doc.setFillColor(style.backgroundColor);
    style.borderColor && this.doc.setDrawColor(...style.borderColor);

    const commonMargin = style.margin || 0;
    const getMargin = (val) =>
      pxToInch(typeof val === "number" ? val : commonMargin || 0);

    style.margin = {};
    style.marginTop = style.margin.top = getMargin(style.marginTop);
    style.marginRight = style.margin.right = getMargin(style.marginRight);
    style.marginBottom = style.margin.bottom = getMargin(
      style.marginBottom
    );
    style.marginLeft = style.margin.left = getMargin(style.marginLeft);

    style.width = pxToInch(style.width);
    style.height = pxToInch(style.height);

    return style;
  }

  addToEveryPage(context) {
    const positionY = this.positionY;
    this.isRelative = true;
    this.positionY = 0;

    const pages = this.doc.getNumberOfPages();
    for (let page = 1; page <= pages; page++) {
      this.doc.setPage(page);
      context(this);
    }

    this.positionY = positionY;
    this.isRelative = false;
  }

  addPageNumbers() {
    const { PADDING, PAGE_HEIGHT } = this;
    const pages = this.doc.getNumberOfPages();

    for (let page = 1; page <= pages; page++) {
      this.doc.setPage(page);
      this.doc.text(
        `Page ${page}/${pages}`,
        PADDING,
        PAGE_HEIGHT - PADDING
      );
    }
  }

  getPosition() {
    return this.positionY;
  }

  increasePosition(value) {
    if (!this.isRelative) {
      this.positionY += value;

      if (this.positionY > this.PAGE_HEIGHT - pxToInch(85)) {
        this.doc.addPage();
        this.positionY = this.headerHeight;
        return true;
      }
    }
    return false;
  }

  getDoc() {
    return this.doc;
  }

  getBase64() {
    return this.doc.output("datauristring");
  }

  download(filename) {
    this.doc.save(filename);
  }
}
